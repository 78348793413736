import { BrowserRouter, Routes, Route, Outlet, Navigate } from "react-router-dom";
import { useState, useEffect } from "react";

import NavBar from "../components/NavBar";
import LoginForm from "../screens/LoginForm";
import DeleteAccount from "../screens/DeleteAccount";
import DeleteConfirmationPopUp from "../screens/DeleteConfirmationPopUp";

const BaseLayout = ({ children }) => {
  return (
    <div className="flex">
      <div className="md:w-full md:mr-4 md:ml-20 md:px-20">
        <div className="md:mr-0 s:mr-2 sm:mr-2 md:ml-20">
          <NavBar />
        </div>
        <div className="md:mr-0 s:mr-2 sm:mr-2 md:ml-20">
          {children}
        </div>
      </div>
    </div>
  );
};

const PrivateRoute = ({ element, isLoggedIn }) => {
  return isLoggedIn ? element : <Navigate to="/" />;
};

export default function MyRoutes() {
  const [isLoggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    // Redirect to login page if not logged in and not already on the login page
    if (!isLoggedIn && window.location.pathname !== "/") {
      window.location.href = "/";
    }
  }, [isLoggedIn]);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <BaseLayout>
              <LoginForm setLoggedIn={setLoggedIn} isLoggedIn={isLoggedIn} />
            </BaseLayout>
          }
        />
        <Route
          path="/deleteAccount"
          element={
            <BaseLayout>
              <PrivateRoute element={<DeleteAccount />} isLoggedIn={isLoggedIn} />
            </BaseLayout>
          }
        />
        <Route
          path="/confirmDelete"
          element={
            <BaseLayout>
              <PrivateRoute element={<DeleteConfirmationPopUp />} isLoggedIn={isLoggedIn} />
            </BaseLayout>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}
