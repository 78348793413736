import React from "react";
import PropTypes from "prop-types";
import InputBase from "@mui/material/InputBase";
import InputCSS from "../inputStyles";

const AppInput = ({
  required,
  endAdornment,
  startAdornment,
  type,
  id,
  value,
  onChange,
  placeholder,
  className,
  sx,
}) => {
  const styles = { ...InputCSS, ...sx };
  return (
    <InputBase
      endAdornment={endAdornment}
      startAdornment={startAdornment}
      type={type}
      id={id}
      required={required}
      value={value}
      onChange={onChange}
      sx={styles}
      className={className}
      placeholder={placeholder}
    />
  );
};

AppInput.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  sx: PropTypes.object,
};

export default AppInput;
