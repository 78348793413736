export const SET_AUTH_TOKEN = "SET_AUTH_TOKEN";

export const SET_ACCOUNT_ID = "SET_ACCOUNT_ID";
export const setAuthToken = (token) => ({
  type: SET_AUTH_TOKEN,
  payload: token,
});

export const setAccountId = (accountId) => ({
  type: SET_ACCOUNT_ID,
  payload: accountId,
});





