import {
  SET_AUTH_TOKEN,
  SET_ACCOUNT_ID

} from "./actions";

const initialState = {
  user: null,
  isLoading: false,
  users: [],
  error: null,
  newUser: null,
  newInfluencer: null,
  currentPage: 0,
  pageSize: 0,
  account: {
    authToken: null,
    id: null,
  },
};


const initialSubState = {
  subscription: null,
  isLoading: false,
  subscriptions: [],
  newSubcription: null
};

const initialAccountState = {
  account: null,
  isLoading: false,
  accounts: [],
  newAccount: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_AUTH_TOKEN:
      return {
        ...state,
        account: {
          ...state.account,
          authToken: action.payload,
        },
      };
    case SET_ACCOUNT_ID:
      return {
        ...state,
        account: {
          ...state.account,
          id: action.payload,
        },
      };
    default:
      return state;
  }
}
