import jwt_decode from "jwt-decode";
import moment from "moment";

function setTokens(tokens) {
  localStorage.setItem("access", tokens.access.token);
  localStorage.setItem("refresh", tokens.refresh.token);
}

// Function to decrypt the issuer hash

function verifyToken(token) {
  const secret = "thisisasamplesecret";
  const decoded = jwt_decode(token);
  const hashedIssuer = decoded.iss;

  const currentTimestamp = moment().unix();

  const decryptedIssuer = atob(hashedIssuer);
  // Use a safe method for comparing the decrypted text with the secret
  if (decryptedIssuer === secret && decoded.exp > currentTimestamp) {
    return true;
  } else return false;
}
export { setTokens, verifyToken };
