import { Button } from "@mui/material";
import React from "react";

const CustomButton = ({
  style,
  children,
  onClick,
  className,
  type,
}) => {
  return (
    <Button
      type={type}
      className={className}
      style={{
        fontFamily: "Work Sans",
        fontStyle: "normal",
        textTransform: "none",
        ...style,
      }}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};
export default CustomButton;
