import React, { useState } from "react";
import { makeStyles, Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid } from "@material-ui/core";
import classNames from "classnames";
import CustomButton from "../components/buttons/CustomButton";
import { useTranslation } from "react-i18next";
import AppTitle from "../components/title/AppTitle";
import { useNavigate } from "react-router-dom";
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
  ApolloLink,
  HttpLink,
} from "@apollo/client";
import { useMutation } from "react-apollo";
import gql from "graphql-tag";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles(() => ({
  modalContent: {
    display: "flex",
    flexDirection: "column",
  },
  connectionButton: {
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    LineWeight: "15px",
  },
  content: {
    padding: "40px", 
    flexDirection: "column",
    alignItems: "center",
  },
  accountDeletedText: {
    color: "#1574F6",
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "22px",
    LineWeight: "26px",
  },
  accountDeletedContent: {
    marginTop: 20,
    display: "flex",
    flexDirection: "column",
    justifyContent: "justify-between",
    alignItems: "center",
  },
  paper: {
    backgroundColor: "white",
    borderRadius: 41,
    position: "relative",
    margin: "auto",
    padding: "40px",
    maxWidth: "800px",
  },
}));

const DELETE_ACCOUNT = gql`
  mutation DeleteAccount($accountId: String!) {
    deleteAccount(accountId: $accountId) {
      account {
        id
        name
        email
        activitySector
        imagesVideos
        website
        logo
        role
      }
    }
  }
`;

const DeleteConfirmationPopUp = ({ onClick, token }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const accountId = useSelector((state) => state.account?.id);
  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_BACKEND_URL,
  });

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: ApolloLink.from([httpLink]),
  });

  const [deleteAccount, { loading, error }] = useMutation(DELETE_ACCOUNT, {
    client,
    onCompleted: () => {
      console.log("Delete account completed!");
      navigate("/deleteAccount");
    },
    onError: (error) => {
      console.error("Delete account mutation error:", error);
      setErrorDialogOpen(true);
    },
  });

  const handleConfirm = (e) => {
    e.preventDefault();
    deleteAccount({
      variables: {
        accountId: accountId,
      },
    });
  };

  const handleCancel = () => {
    navigate("/");
    window.location.reload(true);
  };

  const handleCloseErrorDialog = () => {
    setErrorDialogOpen(false);
  };

  return (
    <ApolloProvider client={client}>
      <Grid container justifyContent="center" alignItems="center" style={{ height: "70vh" }}>
        <Grid item xs={12} sm={10} md={8} lg={6}>
          <div className={classes.paper}>
            <div className={classes.modalContent}>
              <div className={classes.content}>
                <div className="md:flex justify-center font-bold mt-3">
                  <AppTitle
                    title={t("DELETE_USER.confirm_delete")}
                    fontSize={16}
                    fontWeight={500}
                  />
                </div>
                <div className="flex flex-col sm:flex-row  justify-center md:space-x-4 md:space-y-0 sm:space-y-0 sm:space-x-4 mt-6 xs:space-y-3 s:space-y-3 ">
                  <CustomButton
                    onClick={handleCancel}
                    className="w-full text-lg leading-tight focus:outline-none focus:shadow-outline"
                    style={{ padding: "12px 24px", fontSize: "18px", backgroundColor: "#D3D3D3", }}  
                  >
                    <AppTitle
                      title={t("DELETE_USER.cancel")}
                      fontSize={16}
                      fontWeight={500}
                      color={"black"}
                    />
                  </CustomButton>

                  <CustomButton
                    onClick={handleConfirm}
                    type="button"
                    style={{
                      backgroundColor: "#1574F6",
                      padding: "12px 24px",  
                      fontSize: "18px",     
                      alignItems: "center",
                    }}
                    className="w-full text-lg leading-tight focus:outline-none focus:shadow-outline"
                  >
                    <AppTitle
                      title={t("DELETE_USER.accept")}
                      color={"white"}
                      fontSize={16}
                      fontWeight={600}
                    />
                  </CustomButton>
                </div>
            </div>
            <Dialog
              open={errorDialogOpen}
              onClose={handleCloseErrorDialog}
              aria-labelledby="error-dialog-title"
              className={classes.dialog}
            >
              <DialogTitle id="error-dialog-title">Error</DialogTitle>
              <DialogContent>
                <p>Delete account failed. Please try again.</p>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseErrorDialog} color="primary" autoFocus>
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </Grid>
    </Grid>
    </ApolloProvider >
  );
};

export default DeleteConfirmationPopUp;
