import React, { useEffect } from "react";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import Routes from "./routes/routes";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n/i18n";

const client = new ApolloClient({
  uri: process.env.REACT_APP_BACKEND_URL,
  cache: new InMemoryCache(),
});

const App = () => {
  useEffect(() => {
    const splashScreen = document.getElementById("fuse-splash-screen");
    if (splashScreen) {
      splashScreen.style.opacity = 0;
      setTimeout(() => {
        splashScreen.style.visibility = "hidden";
      }, 400);
    }
  }, []);

  return (
    <ApolloProvider client={client}>
      <I18nextProvider i18n={i18n}>
        <div id="root">
          <Routes />
        </div>
      </I18nextProvider>
    </ApolloProvider>
  );
};

export default App;
