import React from "react";
import { makeStyles, Grid } from "@material-ui/core";
import classNames from "classnames";
import { CheckCircle } from "@material-ui/icons";
import CustomButton from "../components/buttons/CustomButton";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles(() => ({
  modalContent: {
    display: "flex",
    flexDirection: "column",
  },
  connectionButton: {
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    LineWeight: "15px",
  },
  content: {
    padding: "70px",
    flexDirection: "column",
    alignItems: "center",
  },
  accountDeletedText: {
    color: "#1574F6",
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "22px",
    LineWeight: "26px",
  },
  accountDeletedContent: {
    marginTop: 20,
    display: "flex",
    flexDirection: "column",
    justifyContent: "justify-between",
    alignItems: "center",
  },
  paper: {
    backgroundColor: "white",
    borderRadius: 41,
    position: "relative",
    margin: "auto",
    padding: "20px",
    maxWidth: "600px",
  },
}));

const DeleteAccount = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const navigateToLogin = () => {
    navigate("/");
    window.location.reload(true);
  };

  return (
    <Grid container justifyContent="center" alignItems="center" style={{ height: "70vh" }}>
      <Grid item xs={12} sm={10} md={8} lg={6}>
        <div className={classes.paper}>
          <div className={classes.modalContent}>
            <div className={classes.content}>
              <div
                className={classNames(
                  classes.accountDeletedContent,
                  "mt-8 flex justify-center items-center"
                )}
              >
                <CheckCircle
                  style={{
                    width: "42px",
                    height: "42px",
                  }}
                  fontSize="large"
                  className="text-blue-600"
                />
                <p
                  className={classNames(
                    classes.accountDeletedText,
                    "flex justify-center items-center w-full py-4 max-w-sm text-center text-lg md:text-xl"
                  )}
                >
                  {t("SUPPRESSION.account_deleted")}
                </p>
                <CustomButton
                  onClick={() => navigateToLogin()}
                  type="button"
                  style={{ backgroundColor: "#1574F6", color: "white" }}
                  className={classNames(
                    classes.connectionButton,
                    "h-12 w-full text-lg md:text-xl leading-tight py-3 md:py-4 px-3 rounded-lg border border-gray-300 focus:outline-none focus:shadow-outline mt-6 md:mt-8 lg:mt-10 xl:mt-12 mb-6"
                  )}
                >
                  {t("LOGIN.login")}
                </CustomButton>
              </div>
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};
export default DeleteAccount;
