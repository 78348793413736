import React from "react";
import { makeStyles } from "@material-ui/core";
import classNames from "classnames";

const useStyles = makeStyles(() => ({
  divTitle: {
    fontFamily: "Work Sans",
    fontStyle: "normal",
  },
}));
const AppTitle = ({
  title,
  secondTitle,
  fontSize,
  fontWeight,
  color,
  className,
  opacity
}) => {
  const classes = useStyles();
  const combinedClassName = classNames(classes.divTitle, className);
  return (
    <div
      className={combinedClassName}
      style={{
        fontSize: fontSize,
        fontWeight: fontWeight,
        color: color,
        opacity
      }}
    >
      <text style={{ fontWeight: "bold" }}>{secondTitle}</text>&nbsp;
      {title}
    </div>
  );
};

export default AppTitle;
