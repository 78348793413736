import React, { useState } from "react";
import { useMutation } from "react-apollo";
import gql from "graphql-tag";
import { Link, useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { ApolloClient } from "@apollo/client";
import { makeStyles } from "@material-ui/core";
import classNames from "classnames";
import { InMemoryCache } from "@apollo/client";
import { ApolloProvider } from "@apollo/client";
import AppInput from "../components/inputs/AppInput";
import InputCSS from "../components/inputStyles";
import { useEffect } from "react";
import CustomButton from "../components/buttons/CustomButton";
import { useTranslation } from "react-i18next";
import { setTokens, verifyToken } from "../tokenService";
import { setAuthToken, setAccountId } from "../redux/actions";
import { useDispatch } from "react-redux";
import Grid from "@mui/material/Grid";
const useStyles = makeStyles(() => ({
  modalContent: {
    display: "flex",
    flexDirection: "column",
  },
  deleteAccountText: {
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "17px",
    LineWeight: "19px",
    margin: "15px 0px 15px 0px",
  },
  modalTitle: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    color: "#1574F6",
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "28px",
    LineWeight: "38px",
  },
  deleteAccountTextTitle: {
    color: "#1574F6",
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "23px",
    LineWeight: "34px",
    marginTop: "15px",
  },
  resetPasswordButton: {
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    LineWeight: "15px",
  },
  content: {
    padding: "70px",
    flexDirection: "column",
    alignItems: "center",
  },
  paper: {
    backgroundColor: "white",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 41,
    position: "relative",
    margin: "auto",
    width: "50%",
    md: "maxW-md",
  },
  centerText: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const client = new ApolloClient({
  uri: process.env.REACT_APP_BACKEND_URL,
  cache: new InMemoryCache(),
});

const LOGIN_USER = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      account {
        id
        name
        email
        activitySector
        password
        imagesVideos
        website
        logo
        role
    }
    tokens {
        access {
            token
            expires
        }
        refresh {
            token
            expires
        }
    }
    }
  }
`;
const LoginForm = ({ handleTokenExists, handleModalState, setLoggedIn }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [open, setOpen] = useState(false);
  const [tokenExists, setTokenExists] = useState(false);
  const [errorText, setErrorText] = useState("");
  const dispatch = useDispatch();


  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const url = window.location.href;
  const [loginUser, { loading, error }] = useMutation(LOGIN_USER, {
    client,
    onCompleted: (data) => {
      const token = data.login.tokens.refresh.token;
      setTokens(data.login.tokens);
      setLoggedIn(true);
      navigate("/confirmDelete");
    },
  });

  useEffect(() => {
    if (url.includes("token")) {
      setTokenExists(true);
      handleTokenExists(tokenExists);
      setOpen(true);
      handleModalState(false);
    }
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await loginUser({
        variables: {
          email,
          password,
        },
      });

      const token = data.login.tokens.refresh.token;
      dispatch(setAuthToken(token));
      dispatch(setAccountId(data.login.account.id));
      setTokens(data.login.tokens);
      setLoggedIn(true);
      navigate("/confirmDelete");
    } catch (error) {
      setErrorText("Wrong email or password");
    }
  };
  return (
    <ApolloProvider client={client}>
      <form onSubmit={handleSubmit}>
        <Grid container justifyContent="center" alignItems="center" style={{ height: "70vh" }}>
          <Grid item xs={12} sm={10} md={8} lg={6}>
            <div className={classNames(classes.paper, "w-full h-400 md:h-auto")}>
              <h1 className={classNames(classes.modalTitle, classes.centerText)}>
                {t("SUPPRESSION.supp")}
              </h1>
              {/* <p className={classes.deleteAccountTextTitle}>
                {t("SUPPRESSION.enter_email")}
              </p> */}
              <p className={classNames(classes.deleteAccountText, "w-full")}>
                {t("SUPPRESSION.credentials")}
              </p>
              {errorText && (
                <p style={{ color: "red", marginBottom: "10px" }}>
                  {errorText}
                </p>
              )}
              <AppInput
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{ ...InputCSS, width: "100%" }}
                className="h-16 md:h-12  w-full text-gray-900 text-lg leading-tight py-2 px-4 rounded-lg border border-gray-400 focus:outline-none focus:shadow-outline "
                placeholder={t("LOGIN.email")}
                style={{
                  "::placeholder": {
                    color: "#B9B9B9",
                    fontStyle: "normal",
                    fontSize: "16px",
                    fontFamily: "Work Sans",
                    LineWeight: "27px",
                    fontWeight: "600",
                  },
                }}
                />
              <AppInput
                value={password}
                placeholder={t("LOGIN.password")}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                className="h-16 md:h-12  w-full  text-gray-800 text-lg leading-tight py-2 px-3 rounded-lg border border-gray-300 focus:outline-none focus:shadow-outline mt-2 sm:mt-4 md:mt-3 lg:mt-8 xl:mt-10 mb-5"
                sx={{ ...InputCSS, width: "100%"}}
                style={{
                  "::placeholder": {
                    color: "#B9B9B9",
                    fontStyle: "normal",
                    fontSize: "16px",
                    fontFamily: "Work Sans",
                    LineWeight: "27px",
                    fontWeight: "600",
                  },
                }}
                id="standard-adornment-password"
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={handleClickShowPassword}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
              <div style={{ display: "flex", flexDirection: "row" }}>
                <CustomButton
                  style={{
                    backgroundColor: "#1574F6",
                    color: "white",
                    opacity: 1,
                    width: "70%",
                    borderRadius: "10px",
                  }}
                  type="submit"
                  onClick={(event) => {
                    handleSubmit(event);
                  }}
                  className={classNames(
                    classes.resetpasswordButton,
                    "h-16 md:h-12  w-full text-gray-800 text-lg leading-tight py-3 px-3 rounded-lg border border-gray-300 focus:outline-none focus:shadow-outline mt-10 sm:mt-6 md:mt-8 lg:mt-10 xl:mt-12"
                  )}
                >
                  {t("SUPPRESSION.supp")}
                </CustomButton>
                <CustomButton
                  style={{
                    backgroundColor: "#D3D3D3",
                    color: "black",
                    opacity: 1,
                    width: "25%",
                    marginLeft: "5%",
                    borderRadius: "10px"
                  }}
                  type="submit"
                  onClick={(event) => {
                    handleSubmit(event);
                  }}
                  className={classNames(
                    classes.resetpasswordButton,
                    "h-16 md:h-12  w-full text-gray-800 text-lg leading-tight py-3 px-3 rounded-lg border border-gray-300 focus:outline-none focus:shadow-outline mt-10 sm:mt-6 md:mt-8 lg:mt-10 xl:mt-12"
                  )}
                >
                  {t("SUPPRESSION.annuler")}
                </CustomButton>
              </div>
            </div>
          </Grid>
        </Grid>
      </form>
    </ApolloProvider>
  );
};
export default LoginForm;
