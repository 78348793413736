import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import deeplyLogo from "../assets/images/deeply-logo.png";

const useStyles = makeStyles(() => ({
  navBar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px",
    backgroundColor: "#ffffff", 
  },
  logo: {
    maxWidth: "150%",
    height: "auto",
  },
}));

const NavBar = () => {
  const classes = useStyles();

  return (
    <div className={classes.navBar}>
      <img src={deeplyLogo} alt="Deeply" className={classes.logo} />
    </div>
  );
};

export default NavBar;
